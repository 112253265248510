export const StopLossField = { label: "اختلاف قیمت (stopLoss)", name: "stopLoss" }
export const VerifyField = { label: "اختلاف برای (verify)", name: "verify" }
export const PriceDifferenceOrderField = { label: "اختلاف قیمت در لحظه ثبت اردر", name: "price_difference_order   " }
export const OpenPositionField = { label: "گام برای (open position)", name: "open_position" }
export const OpenOrderField = { label: "گام برای open اول (open order)", name: "open_order" }
export const NewOpenOrderField = { label: "گام برای cansel open (new open order)", name: "new_open_order" }
export const VerifyAfterZeroField = { label: "وریفای (بعد از صفر)", name: "verify_after_zero" }
export const TreadDone = { label: "ترید انجام شود", name: "tread_done" }
export const ChangeStatusOffset = { label:"متغییر یک سنت", name: "change_status_offset" }


import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../Dashboard';
import RealSnapshot from '../RealSnapshot';
import Header from "./Header";
import SnapshotHistory from '../SnapshotHistory';
import Settings from '../Settings';
import Demo from '../Demo';
import SnapShotDetails from '../SnapShotDetails';
import BingxSettings from '../BingxSettings';


type RouteItem = {
  component: ReactElement
  route: string;
};

const routes: RouteItem[] = [
  {
    component: <Dashboard />,
    route: "/Dashboard",
  },
  {
    component: <RealSnapshot />,
    route: "/RealSnapshot",
  },
  {
    component: <SnapshotHistory />,
    route: "/SnapshotHistory",
  },
  {
    component: <Settings />,
    route: "/Settings",
  },
  {
    component: <Demo />,
    route: "/Demo",
  },
  {
    component: <BingxSettings />,
    route: "/BingxSettings",
  },
  {
    component: <SnapShotDetails />,
    route: "/SnapshotDetails/:id",
  },
];

const Content = () => {
  return (
    <div className='flex-col flex-1 w-full lg:w-[calc(100vw - 350px)]  flex min-h-screen p-4'>
      <Header />
      <Routes>
        {routes.map((routeItem, index) => (
          <Route
            key={index}
            Component={() => routeItem.component}
            path={routeItem.route}
          />
        ))}
      </Routes>
    </div>
  )
}

export default Content



import React from "react";

interface ButtonProps {
    buttonName: string;
  onClick: () => void;
}

const Button = ({ buttonName, onClick }: ButtonProps) => {
  return (
    <div className="w-full  py-4">
      <button
        className="w-full flex justify-center items-center h-12  rounded-lg bg-btn-primary "
        onClick={onClick}
      >
        <span className="text-white font-bold"> {buttonName}</span>
      </button>
    </div>
  );
};

export default Button;

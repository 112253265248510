import useRealSnapShot from "./useRealSnapShot";
import { Table } from "../sections";
import OffsetFilter from "./components/offsetFilter";
const RealSnapshot = () => {
  const { realSnapshotData, offsetHandler, setOffsetInput, offsetInput } =
    useRealSnapShot();
    console.log("ask: ",realSnapshotData.asks.length," bids: ",realSnapshotData.bids.length);

  return (
    <div className="w-full flex flex-col gap-8 pt-10">
      <OffsetFilter
        offsetInput={offsetInput}
        setOffsetInput={setOffsetInput}
        offsetHandler={offsetHandler}
        currentPrice={realSnapshotData.price}
      />
      <div className="w-full grid grid-cols-2 gap-6">
        <Table
          color={"bg-red-500"}
          title={"Asks"}
          data={realSnapshotData.asks}
        />
        <Table
          color={"bg-green-500"}
          title={"Bids"}
          data={realSnapshotData.bids}
        />
      </div>
    </div>
  );
};

export default RealSnapshot;

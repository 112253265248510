import {InputProps} from "../Input/types/InputProps";


export type ToggleProps = {
  onChange?: (value:boolean) => void;
  label?: string;
  checked?: boolean;
  name?: string;
  inputRef?: InputProps['inputRef'];
  onBlur?: InputProps['onBlur'];
}

function Toggle({onChange, label, checked, name, inputRef, onBlur}: ToggleProps) {

  function onChangeHandler(e:any) {
    onChange && onChange(e.target.checked)
  }

  const inputProps = {
    onChange: onChangeHandler,
    type: "checkbox",
    ...checked != false ? { className: "toggle toggle-primary"} : { className: "toggle"},
    ...checked != undefined && {checked},
    ...name && {name},
    ...inputRef && {inputRef},
    ...onBlur && {onBlur},
  }

  return (
    <div className="flex flex-col items-start justify-center">
      {label && <label className="ml-4 mb-2">{label}</label>}

      <input {...inputProps}/>
    </div>
  );
}

export default Toggle
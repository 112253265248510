import axios from "axios";
import { useEffect, useState } from "react";
import { Api } from "../../Api";
import useAxios from "../../Hooks/request/hooks/useAxios";
import useReactHookFormWrapper from "../sections/Form/ReactHookFormWrapper/hooks/useReactHookFormWrapper";
import { toast } from "react-toastify";
import { OpenOrderField, NewOpenOrderField, TreadDone, ChangeStatusOffset, OpenPositionField, VerifyAfterZeroField, VerifyField, PriceDifferenceOrderField, StopLossField } from "./LabelAndNameFields"
import { activityToggleRowName } from "./Fields/ToggleButtonField";
const useSettings = () => {
  const [data, setData] = useState<any>();
  const [getSettingsResponse, getSettings] = useAxios()
  const [updateSettingsResponse, updateSettings] = useAxios()

  async function fetchSettingsHandler() {
    const url = Api.getSettings;

    const res = await getSettings({
      url,
      method: "GET",
    }).then((res) => {
      const dataServer = res.data
      setData(dataServer);
      setValue(PriceDifferenceOrderField.name, dataServer.makerPrice);
      setValue(StopLossField.name, dataServer.stopPrice);
      setValue(VerifyField.name, dataServer.verifyTolerance);
      // setValue(LeverageField.name, dataServer.leverage);
      setValue(OpenOrderField.name, dataServer.openOrderStep);
      setValue(NewOpenOrderField.name, dataServer.newOpenOrderStep);
      setValue(ChangeStatusOffset.name, dataServer.changeStatusOffset);
      setValue(OpenPositionField.name, dataServer.closePositionStep);
      setValue(VerifyAfterZeroField.name, dataServer.verifyToleranceAfterZero);
      setValue(TreadDone.name, dataServer.trade);
      console.log("res: ", dataServer);

    });

  }

  useEffect(() => {
    fetchSettingsHandler().then();
  }, [updateSettingsResponse]);
  const {
    formMethods,
    onSubmit,
  } = useReactHookFormWrapper({
    onSubmitHandler,
  });

  const { setValue, watch, reset } = formMethods;
  function onSubmitHandler(data: any) {
    const dataFinall = {
      makerPrice: Number(data[PriceDifferenceOrderField.name]),
      stopPrice: Number(data[StopLossField.name]),
      trade: data[TreadDone.name],
      verifyTolerance: Number(data[VerifyField.name]),
      // leverage: Number(data[LeverageField.name]),
      openOrderStep: Number(data[OpenOrderField.name]),
      newOpenOrderStep: Number(data[NewOpenOrderField.name]),
      closePositionStep: Number(data[OpenPositionField.name]),
      verifyToleranceAfterZero: Number(data[VerifyAfterZeroField.name]),
      changeStatusOffset: Number(data[ChangeStatusOffset.name])

    };

    const url = Api.updateSettings;
    updateSettings({ url, method: "PUT", data: dataFinall }).then(
      (res) => {
        toast.success("با موفقیت بروزرسانی شد");
      }
    );
  }
  return {
    formMethods,
    onSubmit,
  };
};
export default useSettings;


import React, { useState, useEffect } from "react";
import { SnapshotItemType } from "../useSnapshotDetails";

interface SnapshotTableProps {
  data: SnapshotItemType[];
  title: string;
}

const SnapshotTable = ({ data, title }: SnapshotTableProps) => {
  const [isAscending, setIsAscending] = useState<boolean | null>(null);
  const [sortedData, setSortedData] = useState<SnapshotItemType[]>([]);

  useEffect(() => {
    // Only apply sorting on initial render if isAscending is not null
    if (isAscending !== null) {
      const sortedData = [...data].sort((a, b) => {
        let bVolume = Number(b.volume)
        let aVolume = Number(a.volume)
        return isAscending ? aVolume- bVolume : bVolume - aVolume;
      });
      setSortedData(sortedData);
    } else {
      // If isAscending is null, set sorted data to original data
      setSortedData(data);
    }
  }, [data, isAscending]);

  const toggleSort = () => {
    setIsAscending((prevState) => (prevState === null ? true : !prevState));
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <div>
        <span className="text-lg font-semibold text-white">{title}</span>
      </div>
      <table className="w-full table bg-white">
        <thead className="bg-green-900 text-white">
          <tr>
            <th className="py-3">ردیف</th>
            <th onClick={toggleSort} className="py-3 cursor-pointer">
              حجم
              {isAscending === true && <span> ▲</span>}
              {isAscending === false && <span> ▼</span>}
            </th>
            <th className="py-3">قیمت</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index} className="text-baes text-gray-900">
              <td className="">{index + 1}</td>
              <td>{item.volume}</td>
              <td>{item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SnapshotTable;

import { useEffect, useState } from "react";
import { socketClient } from "../../Socket";
import axios from "axios";
import { Api } from "../../Api";

type OrderType =
  | "all orders"
  | "1$"
  | "3$"
  | "5$"
  | "10$"
  | "20$"
  | "50$"
  | "100$"
  | "200$"
  | "400$"
  | "600$"
  | "800$";
const orderList: OrderType[] = [
  "all orders",
  "1$",
  "3$",
  "5$",
  "10$",
  "20$",
  "50$",
  "100$",
  "200$",
  "400$",
  "600$",
  "800$",
];

export type OrderbookItemType = {
  buy: number;
  sell: number;
  qty: number;
  darsad: number;
  pish: number;
  status: "buy" | "sell";
  order: OrderType;
};
const defaultOrderbook: OrderbookItemType[] = [
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
  { status: "buy", sell: 0, buy: 0, qty: 0, darsad: 0, pish: 0, order: "1$" },
];

type PriceStatusType = "Ascending" | "Descending";
const useDashboard = () => {
  const [orderbook, setOrderbook] = useState(defaultOrderbook);
  const [orderbookPrice, setOrderbookPrice] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [priceStatus, setPriceStatus] = useState<PriceStatusType>("Ascending");

  const getSnapshot = async () => {
    axios.post(Api.createSnapshotHistory, {}).then((data) => {
      window.alert("اطلاعات با موفقیت ثبت ش");
    }).catch((e)=>{
      console.log("******",e)
      window.alert("مشکلی پیش آمده");
    })
  };

  useEffect(() => {
    socketClient.emit("join-page", "orderBook");
    socketClient.emit("join-page", "anyPrice");

    socketClient.on("res", (data) => {
      console.log("on res",data);
      
      if (orderbookPrice > data[1]) {
        setPriceStatus("Descending");
      } else {
        setPriceStatus("Ascending");
      }
      setOrderbookPrice(data[1]);
      let array: OrderbookItemType[] = [];
      for (let i = 0; i < 12; i++) {
        let orderbookItem: OrderbookItemType = {
          status: "buy",
          sell: 0,
          buy: 0,
          qty: 0,
          darsad: 0,
          pish: 0,
          order: orderList[i],
        };

        orderbookItem.sell = data[i + 3].a;
        orderbookItem.buy = data[i + 3].b;
        orderbookItem.qty = data[i + 3].q;
        orderbookItem.darsad = data[i + 3].d;
        // orderbookItem.pish = data[i + 3].d * data[i + 3].q + data[1];
        orderbookItem.pish=data[i + 3].p
        if (orderbookItem.buy > orderbookItem.sell) {
          orderbookItem.status = "buy";
        } else {
          orderbookItem.status = "sell";
        }

        array.push(orderbookItem);
      }

      setOrderbook([...array]);
    });

    socketClient.on("price-res", (priceData: number) => {
      // console.log("on price-res");
      setCurrentPrice(priceData);
    });
    return () => {
      socketClient.off("res");
      socketClient.off("price-res");
    };
  }, []);

  return { orderbook, currentPrice, orderbookPrice, priceStatus, getSnapshot };
};

export default useDashboard;

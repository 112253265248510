import {useForm, UseFormProps} from "react-hook-form";
import useReRenderReactHookFormWhenMount from "../../../../../Hooks/ReactHookForm/useReRenderReactHookFormWhenMount";

type Props<FormValues> = {
  onSubmitHandler: (data:FormValues) => void;
  defaultValues?: FormValues;
}

function useReactHookFormWrapper<FormValues extends Object>({onSubmitHandler, defaultValues}: Props<FormValues>) {

  const formMethods = useForm<FormValues>({defaultValues, mode: 'onChange'} as UseFormProps<FormValues, any> | undefined)
  const {handleSubmit, getValues} = formMethods
  const onSubmit = handleSubmit(onSubmitHandler)

  useReRenderReactHookFormWhenMount({getValues})

  return {onSubmit, formMethods}
}

export default useReactHookFormWrapper;
import { constants } from "../constants/constants";

const baseApi = constants.apiUrl;
// const baseApi = "http://localhost:4000/";
const Api = {
  Login: `login`,
  createSnapshotHistory:`${baseApi}api/insert-snapshot`,
  getSettings:`get-setting`,
  updateSettings:`update-setting`,
  getSnapshotHistories:`${baseApi}api/get-snapshot-history`,
  getSnapshotDetails:`${baseApi}api/get-snapshot-details`,
  deleteSnapshotHistory:`${baseApi}api/remove-snapshot`,
  deleteFuturesHistory:`delete-futures-history`,
  getFuturesHistory:`get-futures-history`,
  getBingSetting:`get-bing-setting`,
  updateBingSetting:`update-bing-setting`,
};

export { Api, baseApi };
import Card from "../sections/Card";
import SnapshotFilter from "./components/SnapshotFilter";
import SnapshotTable from "./components/snapshotTable";
import useSnapshotDetails from "./useSnapshotDetails";


const SnapShotDetails = () => {
  const { snapShotData,formMethods,  onSubmit } =
    useSnapshotDetails();


  return (
    <Card title='جزییات'>

    <div className="w-full flex flex-col">
      <SnapshotFilter
        formMethods={formMethods}
        onSubmit={onSubmit}
        // watch={watch}
        // setValue={setValue}
      />
      <div className="flex w-full gap-6 flex-row">
        <SnapshotTable data={snapShotData.asks} title="Asks" />
        <SnapshotTable data={snapShotData.bids} title="BidS" />
      </div>
    </div>
    </Card>

  );
};

export default SnapShotDetails;

import {InputProps} from "./types/InputProps";

type Props = {
  errorMessage: InputProps['errorMessage'];
}

function InputErrorMessage({errorMessage}: Props) {
  console.log("fsfsf", errorMessage);
  
  return (
    <p className='text-red-500 h-4 text-xs font-medium'>
      {errorMessage || ''}
    </p>
  )
}

export default InputErrorMessage
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";
import { SnapShotFilterType } from "../useSnapshotDetails";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import NumberSettingsField from '../../Settings/Fields/NumberSettingsField';
import { PriceFrom, PriceTo } from "../LabelAndNameFields"
import Button from "../../sections/Button/Button";
import ReactHookFormWrapper from "../../sections/Form/ReactHookFormWrapper/ReactHookFormWrapper";
import ToggleButtonField from '../../Settings/Fields/ToggleButtonField';


interface SnapshotFilterProps {
  formMethods: any;
  onSubmit: any;
}

const SnapshotFilter = ({
  formMethods,
  onSubmit,
}: SnapshotFilterProps) => {
  return (
    <>
      <ReactHookFormWrapper
        formMethods={formMethods}
        onSubmit={onSubmit}
        hiddenButton
        className={`flex flex-col justify-start py-4  rounded-2xl w-full`}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3">

          <NumberSettingsField notRequired={true} nameFieldProps={PriceFrom.name} labelFieldProps={PriceFrom.label} />
          <NumberSettingsField notRequired={true} nameFieldProps={PriceTo.name} labelFieldProps={PriceTo.label} />

        </div>

        <div className="flex gap-2 pt-4  justify-start">
          <>
            <Button
              color="primary"
              size="md"
              type="submit"
              className={{ extra: "px-4 py-2 rounded-lg" }}
            >
              اعمال تنظیمات
            </Button>
          </>
        </div>

      </ReactHookFormWrapper>

      <form
        // onSubmit={handleSubmit(onSubmit)}
        className=" p-3 flex flex-col rounded-lg gap-3 "
      >
        <div className="flex flex-row gap-3">
          {/* 
          <TextField
            label="از قیمت"
            id="fromPrice"
            type="number"
            value={watch("fromPrice")}
            onChange={(e) => setValue("fromPrice", e.target.value)}
          />

          <TextField
            label="تا قیمت"
            id="toPrice"
            type="number"
            value={watch("toPrice")}
            onChange={(e) => setValue("toPrice", e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox checked={watch("sortVolume")} onChange={(e) => setValue("sortVolume", e.target.checked)} />
            }
            label="حجم صعودی به نزولی"
          /> */}
        </div>


        {/* <Button type="submit" variant="contained">
        اعمال
      </Button> */}
      </form>
    </>

  );
};

export default SnapshotFilter;

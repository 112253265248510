import useSnapshotHistory from "./useSnapshotHistory";
import SnapshotHistoryTable from "./components/SnapshotHistoryTable";
import Card from "../sections/Card";

const SnapshotHistory = () => {
  const { snapShothistoryData, onDeleteSnapshotHistory, onShowSnapshotDetail } =
    useSnapshotHistory();
  return (
    <Card title='اسنپ شات'>

      <div className="w-full py-4 flex flex-col">
        <div>
          <SnapshotHistoryTable
            onDeleteSnapshotHistory={onDeleteSnapshotHistory}
            onShowSnapshotDetail={onShowSnapshotDetail}
            snapshotHistoryData={snapShothistoryData}
          />
        </div>
      </div>
    </Card>
  );
};

export default SnapshotHistory;

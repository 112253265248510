"use client"

import {useEffect} from "react";
import rerenderReactHookForm from "../../utils/ReactHookForm/rerenderReactHookForm";
import {UseFormGetValues} from "react-hook-form";


type Props = {
  getValues: UseFormGetValues<any>
}

function useReRenderReactHookFormWhenMount({getValues}: Props) {
  useEffect(function () {
    rerenderReactHookForm({getValues})
  }, [])
}

export default useReRenderReactHookFormWhenMount
import { useEffect, useState } from "react";
import { AxiosRequestConfig } from "axios";
import { axiosProvider } from "../AxsoisProvider";
import { getErrorStatus, getResponseError } from "../utils/getResponse";
// import useLogout from "@/hooks/auth/useLogout";
// import Ls from "@/utils/customLocalStorage";

export type ResponseType = {
  data: any;
  error: any;
  loading: boolean;
  status: any;
};

export const beforeLoginUrl = "beforeLoginUrl"

const useAxios = () => {
 
  const [response, setResponse] = useState<ResponseType>({
    data: null,
    error: null,
    loading: false,
    status: null,
  });

  // const logout = useLogout();

  const sendRequest = async function (props: AxiosRequestConfig) {

    setResponse((prev) => ({ ...prev, loading: true, error: null }));
    try {
      const result = await axiosProvider.request(props);
      console.log("result: ",result);
      
      console.log("props: ",props);
      

      setResponse((prev) => ({
        ...prev,
        data: result.data,
        loading: false,
        status: result.status,
        error: null,
      }));
      return result.data;
    } catch (error) {
      // if ([403, 401].includes(getErrorStatus(error))) {
        // Ls.add(beforeLoginUrl, window.location.pathname + window.location.search);
        // logout();
      // }

      console.log('1: ',error);
      

      setResponse((prev) => ({
        ...prev,
        error: getResponseError(error),
        status: "",
        loading: false,
      }));

      console.log("2: ",getResponseError(error));
      
      return Promise.reject(getResponseError(error));
    }
  };
  return [response, sendRequest] as const;
};

export default useAxios;

import axios from "axios";
import { useParams } from "react-router-dom";
import { Api } from "../../Api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PriceFrom, PriceTo } from "./LabelAndNameFields"
import useReactHookFormWrapper from "../sections/Form/ReactHookFormWrapper/hooks/useReactHookFormWrapper";
import useAxios from "../../Hooks/request/hooks/useAxios";
import { toast } from "react-toastify";
import { removeItemFromObjectWhenIsNullOrEmptyString } from "../../utils/isNullOrEmptyString";

export type SnapshotItemType = {
  price: string;
  volume: string;
  type: "asks" | "bids";
};

export type SnapShotType = {
  asks: SnapshotItemType[];
  bids: SnapshotItemType[];
};
export type SnapShotFilterType = {
  fromPrice: string;
  toPrice: string;
  sortVolume: boolean;
};

type ParamsType = {
  PID: string;
  fromPrice?: string;
  toPrice?: string;
  sort?: "desc_volume";
};

const useSnapshotDetails = () => {
  const { id } = useParams();
  const [snapShotData, setSnapshotData] = useState<SnapShotType>({
    asks: [],
    bids: [],
  });
  const [query, setQuery] = useState<Object | string>({ PID: id || "" });

  const [getSnapshotDetailsResponse, getSnapshotDetails] = useAxios()
  async function fetchSnapshotDetailsListHandler() {
    const url = Api.getSnapshotDetails;
    const tempSnapshot: SnapShotType = {
      asks: [],
      bids: [],
    };
    getSnapshotDetails({
      url, method: "GET", params: {
        ...removeItemFromObjectWhenIsNullOrEmptyString(query),
      }
    }).then(
      (response) => {
        console.log("response", response);

        if (response.data) {
          response.data.forEach((item: SnapshotItemType) => {
            if (item.type === "asks") {
              tempSnapshot.asks.push(item);
            } else {
              tempSnapshot.bids.push(item);
            }
          });
          setSnapshotData({ ...tempSnapshot });
        }
      }
    );
  }
  const onSubmitHandler = (data: any) => {
    if (id) {
      const finalData = {
        PID: id || "",
        ...(data[PriceFrom.name] == 0 || data[PriceFrom.name] == ""
        ? null
        : { fromPrice: data[PriceFrom.name] }),
        ...(data[PriceTo.name] == 0 || data[PriceTo.name] == ""
          ? null
          : { toPrice: data[PriceTo.name] }),
      };

      setQuery(finalData)
    } else {

    }
  }
  const {
    formMethods,
    onSubmit,
  } = useReactHookFormWrapper({
    onSubmitHandler,
  });

  useEffect(() => {
    fetchSnapshotDetailsListHandler().then();

  }, [query]);

  return { snapShotData, onSubmit, formMethods };
};

export default useSnapshotDetails;

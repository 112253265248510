import Button from "../../sections/Button/Button";
import { SnapShothistoryType } from "../useSnapshotHistory";
import moment from "moment";

interface SnapshotHistoryTableProps {
  snapshotHistoryData: SnapShothistoryType[];
  onDeleteSnapshotHistory: (id: string) => void;
  onShowSnapshotDetail: (id: string) => void;
}

const SnapshotHistoryTable = ({
  snapshotHistoryData,
  onDeleteSnapshotHistory,
  onShowSnapshotDetail,
}: SnapshotHistoryTableProps) => {
  return (
    <div className="shadow-lg overflow-hidden  border border-white/15   w-full h-full rounded-2xl">

      <table className="w-full  table">
        <thead className="bg-sec text-white">
          <tr>
            <th className="py-3">ردیف</th>
            <th>شناسه</th>
            <th>تاریخ ثبت</th>
            <th> نمایش جزئیات</th>
            <th>عملیات</th>
          </tr>
        </thead>
        <tbody className="bg-sec text-white">
          {snapshotHistoryData.map((item, index) => (
            <tr key={index} className={`text-lg ${index % 2 === 0 ? "bg-primary/60" : ""} `}>
              <td className="py-3">{index + 1}</td>
              <td>{item.lastUpdateId}</td>
              <td>{moment(item.createdAt).format("llll")}</td>
              <td>
                <Button
                  size="sm"
                  className={{ extra: "px-2 py-1 rounded-lg" }}
                  onClick={() => onShowSnapshotDetail(item._id)}
                // className="px-4 py-2 text-gray-900 text-lg hover:text-gray-200 bg-green-600 rounded-sm hover:bg-green-950 transition-all duration-300"
                // onClick={() => onShowSnapshotDetail(item._id)}
                >
                  نمایش جزئیات
                </Button>
              </td>
              <td>
                <Button
                  color="danger"
                  size="sm"
                  className={{ extra: "px-2 py-1 rounded-lg" }}
                  onClick={() => onDeleteSnapshotHistory(item._id)}
                //     className="px-4 py-2 text-gray-900 text-lg hover:text-gray-200 bg-red-600 rounded-sm hover:bg-red-900 transition-all duration-300"
                //  onClick={() => onDeleteSnapshotHistory(item._id)}
                >
                  حذف
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

  );
};

export default SnapshotHistoryTable;

import clsx from "clsx";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMenu } from "react-icons/io5";

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  type MenuItem = {
    title: string;
    onClick: () => void;
    url: string
  };

  const menus: MenuItem[] = [
    {
      title: "داشبورد",
      onClick: () => {
        navigate("/Dashboard");
      },
      url: "/Dashboard"
    },
    {
      title: "اسنپ شات لحظه ای",
      onClick: () => {
        navigate("/RealSnapshot");
      },
      url: "/RealSnapshot"

    },
    {
      title: "اسنپ شات",
      onClick: () => {
        navigate("/SnapshotHistory");
      },
      url: "/SnapshotHistory"

    }, {
      title: "دمو",
      onClick: () => {
        navigate("/Demo");
      },
      url: "/Demo"

    },
    {
      title: "تنظیمات صرافی ",
      onClick: () => {
        navigate("/BingxSettings");
      },
      url: "/BingxSettings"

    },
    {
      title: "تنظیمات",
      onClick: () => {
        navigate("/Settings");
      },
      url: "/Settings"

    },

  ];

  return (
    <>
      <div
        className={clsx(
          " bg-primary absolute rounded-tl-3xl z-10  lg:relative right-0 top-0 min-h-screen transition-all duration-300 flex flex-col",
          {
            "w-[270px] lg:w-[350px]": isMenuOpen,
            "w-0": !isMenuOpen,
          }
        )}
      >
        <div className="p-4">
          <IoMenu
            className="text-4xl text-white mr-auto cursor-pointer "
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </div>
        <div
          className={clsx("flex flex-col gap-6", {
            flex: isMenuOpen,
            hidden: !isMenuOpen,
          })}
        >
          {menus.map((menuItem, index) => (
            <a key={index} href={menuItem.url} className="text-white  ">
              {menuItem.title}
            </a>
          ))}
        </div>
      </div></>
  );
};

export default Menu;

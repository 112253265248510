import React from 'react'
import useSettings from './useSettings'
import Card from "../sections/Card";
import ReactHookFormWrapper from '../sections/Form/ReactHookFormWrapper/ReactHookFormWrapper';
import NumberSettingsField from './Fields/NumberSettingsField';
import { OpenOrderField, NewOpenOrderField, OpenPositionField, TreadDone, VerifyAfterZeroField, ChangeStatusOffset, VerifyField, PriceDifferenceOrderField, StopLossField } from "./LabelAndNameFields"
import Button from '../sections/Button/Button';
import ToggleButtonField from './Fields/ToggleButtonField';
const Settings = () => {
    const { formMethods,
        onSubmit, } = useSettings()
    return (
        <Card title='تنظیمات'>
            <ReactHookFormWrapper
                formMethods={formMethods}
                onSubmit={onSubmit}
                hiddenButton
                className={`flex flex-col justify-start py-4  rounded-2xl w-full`}
            >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3">
                    <NumberSettingsField nameFieldProps={StopLossField.name} labelFieldProps={StopLossField.label} />
                    <NumberSettingsField nameFieldProps={PriceDifferenceOrderField.name} labelFieldProps={PriceDifferenceOrderField.label} />
                    <NumberSettingsField nameFieldProps={VerifyField.name} labelFieldProps={VerifyField.label} />
                    <NumberSettingsField nameFieldProps={VerifyAfterZeroField.name} labelFieldProps={VerifyAfterZeroField.label} />
                    <NumberSettingsField nameFieldProps={OpenPositionField.name} labelFieldProps={OpenPositionField.label} />
                    <NumberSettingsField nameFieldProps={NewOpenOrderField.name} labelFieldProps={NewOpenOrderField.label} />
                    <NumberSettingsField nameFieldProps={OpenOrderField.name} labelFieldProps={OpenOrderField.label} />
                    <NumberSettingsField nameFieldProps={ChangeStatusOffset.name} labelFieldProps={ChangeStatusOffset.label} />
                    <div className="mt-3 px-3">
                        <ToggleButtonField nameFieldProps={TreadDone.name} labelFieldProps={TreadDone.label} />
                    </div>
                </div>
                <div className="flex gap-2 pt-4  justify-start">
                    <>
                        <Button
                            color="primary"
                            size="md"
                            type="submit"
                            className={{ extra: "px-4 py-2 rounded-lg" }}
                        >
                            اعمال تنظیمات
                        </Button>
                    </>
                </div>
            </ReactHookFormWrapper>
        </Card>

    )
}

export default Settings
import React from "react";
interface offsetFilterProps {
  offsetInput: number;
  setOffsetInput: (value: number) => void;
  offsetHandler: () => void;
  currentPrice: number;
}
const OffsetFilter = ({
  offsetInput,
  setOffsetInput,
  offsetHandler,
  currentPrice,
}: offsetFilterProps) => {
  return (
    <div className="flex flex-col-reverse lg:flex-row justify-between w-full items-center gap-6">
      <div className="lg:w-2/4  flex  gap-3">
        <input
          type="number"
          className="w-full lg:w-1/2 max-sm:flex-1 h-12 outline-gray-800 border rounded-md border-gray-600 text-lg font-semibold px-3 text-white"
          value={offsetInput}
          onChange={(e) => setOffsetInput(Number(e.target.value))}
        />
        <button
          className="px-6 py-3 w-40 rounded-md bg-gray-600 text-white"
          onClick={offsetHandler}
        >
          اعمال فیلتر
        </button>
      </div>
      <div  className="lg:w-2/4 flex-col lg:flex-row    flex gap-2 lg:justify-end px-4">
        <span className="lg:text-lg text-white">قیمت لحظه:</span>
        <span className="text-lg text-yellow-500">{currentPrice}</span>
      </div>

    </div>
  );
};

export default OffsetFilter;

import React from 'react'
import { socketClient } from "../../Socket";
import { useEffect, useState } from "react";
import useAxios from '../../Hooks/request/hooks/useAxios';
import { Api } from '../../Api';
import { toast } from 'react-toastify';

export interface StatusRecordType {
  // FutureHistoryData:[],
  isOpen: boolean;
  entryPrice: number;
  orderStatus: string;
  tolerancePrice: number;
  endPrice: number;
  checkAfterZero: boolean;
  checkAfterZeroStep: boolean;
  bingEntryPrice: number;
  bingOrderStatus: string;
  secondConditionForZero: boolean;
  demoPositionStepOrder: string;
  price: number;
  status: string;
  isNewStep: boolean;
  step: number;
  asksOneCent: string;
  bidsOneCent: string;
  bidsOneCentArray: [string, string][];
  asksOneCentArray: [string, string][];
  isPendingForPrice: boolean;
  cancelOrderCount: number;
  priceStep: number;
  priceDiffAfterZero: number;
  bingBTCPrice: number;
  bingOrder: any;
  priceQueue: any[];
  isPendingOrder: boolean;
  bingTrade: boolean;
  demoTrade: boolean;
  type: string
}

function useDemo() {
  const [statusRecord, setStatusRecord] = useState<StatusRecordType[]>([])
  const [data, setData] = useState<any>();
  const [signalFront, setSignalFront] = useState<any>();
  const [ethsignalFront, setEthSignalFront] = useState<any>();
  const [deleteFuturesHistoryResponse, deleteFuturesHistory] = useAxios()
  const [reload, setReload]=useState<boolean>(false)
  const [count, setCount]=useState<number>(0)
  async function deleteFuturesHistoryHandler() {
    const url = Api.deleteFuturesHistory;

    const res = await deleteFuturesHistory({
      url,
      method: "DELETE",
    }).then((res) => {
      toast.success(res.message)
    });
  }

  useEffect(() => {
    console.log("get Socket ")
    socketClient.emit("join-page", "statusRecord");
    socketClient.emit("join-page", "bingOrderStatus");
    socketClient.emit("join-page", "ethSignalFrontRoom");
    socketClient.emit("join-page", "btcSignalFrontRoom");
    socketClient.on("status", (data) => {
      setStatusRecord(data)
      console.log("status****: ",data.length, data);
      setData(data.FutureHistoryData)
    })
    socketClient.on("btcSignalFront", (data) => {
      setSignalFront([...data])
      // console.log("dataeeee: ", data);
    })
    socketClient.on("ethSignalFront", (data) => {
      setEthSignalFront([...data])
      // console.log("dataeeee: ", data);
    })

  }, [reload]);
  
  useEffect(() => {
    const t = setInterval(()=>{
      setReload((old)=>!old)
      console.log("Reload ...")
    },60000)

  }, []);

  
  
  
  return { statusRecord, data, deleteFuturesHistoryHandler, signalFront, ethsignalFront };
}
export default useDemo;

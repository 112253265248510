import {useController, useFormContext} from "react-hook-form";
import withSeparator from "../../../../../utils/separator/withSeparator";
import convertPersianNumberToEnglish from "../../../../../utils/convertPersianNumberToEnglish";
import removeSeparator from "../../../../../utils/separator/removeSeparator";
import {InputFormPropsType} from "../InputForm";


function useInputForm({fieldName, rules,maxLength, bySeparator, justNumber, onEnter, defaultValue}: InputFormPropsType) {

  const {control} = useFormContext()

  const {
    field: {onChange, onBlur, name, value, ref},
  } = useController({
    name: fieldName,
    control,
    rules,
    defaultValue: defaultValue || "",
  });

  function onKeyDownJustNumber(e:any) {
    const keyCode = e.which
    const allowedKeys = [8, 13, 46]

    if (!(keyCode > 47 && keyCode < 58) && !(keyCode > 1775 && keyCode < 1786) && !allowedKeys.includes(keyCode)) {
      e.preventDefault();
    }
  }

  function setSeparator(e:any) {
    e.target.value = withSeparator(e.target.value)
  }

  function changePersianNumberToEnglish(e:any) {
    e.target.value = convertPersianNumberToEnglish(e.target.value)
  }

  function onChangeHandler(e:any) {
    const value = removeSeparator(e.target.value)
    if (maxLength && value.length > maxLength) return
    if (bySeparator) setSeparator(e)
    if (justNumber) changePersianNumberToEnglish(e)

    onChange(e)
  }

  function onKeyDownHandler(e:any) {
    if (!justNumber) return

    onKeyDownJustNumber(e)

    const keyCode = e.which
    const isEnterKey = keyCode === 13

    if (isEnterKey && onEnter) {
      onEnter()
      e.preventDefault();
    }
  }

  return {
    name, onChangeHandler, onBlur, onKeyDownHandler, value, ref
  }
}

export default useInputForm;
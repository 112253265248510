import useGetFormErrorMessage from "../../../Hooks/ReactHookForm/useGetFormErrorMessage";
// import getInputPlaceholder from "@/components/Form/Input/utils/getInputPlaceholder";
// import formPatterns from "@/constance/form/formPatterns";
// import { requiredInputErrorMassage } from "@/constance/form/requiredInputMassage";
import ToggleForm from "../../../components/sections/Form/Toggle/ToggleForm";

export const activityToggleRowName = "activity" as const;
export const activityToggleLabel = "ترید انجام شود";
export type ActivityToggleType = string;

type Props = {
  notRequired?: boolean;
  checkMinLength?: boolean;
  checkMaxLength?: boolean;
  nameFieldProps: string;
  labelFieldProps: string;
};

function ToggleButtonField({
  notRequired,
  nameFieldProps,
  labelFieldProps,
  checkMinLength = true,
  checkMaxLength = true,
}: Props) {
  const getErrorMessage = useGetFormErrorMessage();
  
  return (
    // <InputForm
    //   fieldName={directoryNameLabel}
    //   inputProps={{
    //     label: directoryNameLabel,
    //     errorMessage,
    //     placeholder,
    //   }}
    //   rules={{
    //     ...checkMinLength && {minLength: formPatterns.minLengthChar(3)},
    //     ...notRequired ? {} : {required: requiredInputErrorMassage},
    //   }}
    //   {...checkMaxLength && {maxLength:50} }
    // />
    <ToggleForm
      fieldName={nameFieldProps}
      inputProps={{ label: labelFieldProps }}
    />
  );
}

export default ToggleButtonField;

import React from "react";
import { OrderbookItemType } from "./useDashboard";
import clsx from "clsx";

interface OrderItemProps {
  orderbookItem: OrderbookItemType;
}

const OrderbookItem = ({ orderbookItem }: OrderItemProps) => {
  type FieldItem = {
    title: string;
    value: number | string;
  };
  const fields: FieldItem[] = [
    {
      title: "Sell",
      value: orderbookItem.sell,
    },
    {
      title: "buy",
      value: orderbookItem.buy,
    },
    {
      title: "qty",
      value: orderbookItem.qty,
    },
    {
      title: "pish",
      value: orderbookItem.pish,
    },
    {
      title: "",
      value: `(${orderbookItem.darsad})`,
    },
  ];

  return (
    <div
      className={clsx("flex  flex-1 flex-col gap-2 p-4 rounded-lg items-end bg-opacity-40", {
        "bg-green-bord": orderbookItem.status === "buy",
        "bg-red-bord": orderbookItem.status === "sell",
      })}
    >
      <div className="w-full border-b border-b-gray-800">
        <span className="text-sm  text-white">{orderbookItem.order}</span>
      </div>
      {fields.map((fieldItem, index) => {
        return (
          <div className="w-full justify-between items-center flex" key={index}>
            <span className={`text-sm md:text-xs lg:text-baes font-bold text- ${fieldItem.title !== "buy" && fieldItem.title !== "Sell"
                ? "text-white" : fieldItem.title === "buy"
                  ? "text-green-500"
                  : "text-red-500"}`}>{fieldItem.value}</span>
            <span className="text-sm text-white">{fieldItem.title}</span>
          </div>
        )
      })}
      {/* <div className="w-full justify-between flex items-start">
        <div>
          <span>{orderbookItem.pish}</span>
          <br />
          <span>({orderbookItem.darsad})</span>
        </div>

        <span>pish</span>
      </div> */}
    </div>
  );
};

export default OrderbookItem;

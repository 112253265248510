import {InputProps} from "../types/InputProps";

function useInputStyles(
  {
    wrapperClassName, errorMessage, inputClassName, labelClassName, startAdornment
  }: Pick<InputProps, 'wrapperClassName' | 'errorMessage' | 'inputClassName' | 'labelClassName' | 'startAdornment'>
) {

  const wrapperStyles = {
    default: "flex flex-col gap-2 text-base text-black ",
    ...wrapperClassName,
  };

  const inputStyles = {
    // default: ` ${
    //   errorMessage ? `border-red-500` : ` border-gray-300  hover:border-gray-400 focus:border-primary/50`
    // } outline-none block w-full rounded-3xl  border-1 appearance-none bg-[#F1F5F9] duration-200`,
    // fontSize: `text-sm`,
    // padding: `${startAdornment ? 'pl-4 pr-12' : 'px-4'} py-4`,
    // ...inputClassName,
  };

  const labelStyles = {
    default: 'flex items-center justify-between',
    ...labelClassName,
  };

  return {
    wrapperStyles, inputStyles, labelStyles
  }
}

export default useInputStyles;
import useGetFormErrorMessage from "../../../Hooks/ReactHookForm/useGetFormErrorMessage";
import getInputPlaceholder from "../../sections/Form/Input/utils/getInputPlaceholder";
import InputForm from "../../sections/Form/Input/InputForm";
import formPatterns from "../../../constants/form/formPatterns";
import { requiredInputErrorMassage } from "../../../constants/form/requiredInputMassage";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";


export const passwordFieldName = "password";
export const passwordlabel = "رمز عبور";
export type PasswordType = string;

type PasswordFieldType = {
  endAdornment?: boolean;
  checkPasswordRepeatFieldName?: string;
  showError?: boolean

};

function PasswordField({
  endAdornment = true,
  checkPasswordRepeatFieldName,
  showError = true
}: PasswordFieldType) {
  const { watch } = useFormContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const getErrorMessage = useGetFormErrorMessage();
  const errorMessage = getErrorMessage( passwordFieldName );
  const placeholder = getInputPlaceholder( passwordlabel);

  const VisibilityIcon = useCallback(() => {
    const iconClass = "h-full w-auto";

    return (
      <div
        className="cursor-pointer h-full w-full text-gray-500 "
        onClick={() => setShowPassword((prev) => !prev)}
      >
        {showPassword ? (
          <MdOutlineVisibility className="h-full w-auto" />
        ) : (
          <MdOutlineVisibilityOff className={iconClass} />
        )}
      </div>
    );
  }, [showPassword]);
  
  return (
    <>
      <InputForm
        fieldName={passwordFieldName
        }
        inputProps={{
          label:  passwordlabel,
          autoCompleteOff: false,
          type: showPassword || !endAdornment ? "text" : "password",
          ...(showError
            ? { errorMessage }
            : null),
          endAdornment: endAdornment && <VisibilityIcon />,
        }}
        rules={{
          required: requiredInputErrorMassage,
          minLength: formPatterns.minLengthChar(4),
        }}
        maxLength={50}
      />
    </>
  );
}

export default PasswordField;

import React, { useState } from 'react';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';

const Table = ({ color, title, data }: any) => {
  const [isAscending, setIsAscending] = useState(true);

  const sortData = () => {
    const sortedData = [...data].sort((a: any, b: any) => {
      return isAscending ? a[1] - b[1] : b[1] - a[1];
    });
    return sortedData;
  };

  const Icon = isAscending ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />;

  return (
    <div>
      <div className={`w-full flex justify-start ${color}`}>
        <div className="text-lg text-white p-2">{title}</div>
      </div>
      <table className={`${color} bg-opacity-30 py-3  w-full `}>
        <thead className=" w-full">
          <tr className=" text-white p-3">
            <th>#</th>
            <th className=' cursor-pointer flex gap-2 justify-center' onClick={() => setIsAscending(!isAscending)}>
              <span className=' self-center'>Depth</span> <span className=' self-center'>{Icon}</span>
            </th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {sortData().map(([price, depth]: any, index: number) => (
            <tr key={index} className="p-3 text-white w-full border-b border-gray-400">
              <td className="p-2">{index + 1}</td>
              <td>{depth}</td>
              <td>{price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

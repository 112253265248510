import React from "react";
import useDashboard from "./useDashboard";
import OrderbookItem from "./OrderbookItem";
import clsx from "clsx";
import { Button } from "../sections/index";
import Card from "../sections/Card";
const Dashboard = () => {
  const { orderbook, currentPrice, orderbookPrice, priceStatus , getSnapshot } = useDashboard();

  return (
    <div className="w-full ">
      <Button buttonName='گرفتن اسنپ شات' onClick={() => { getSnapshot()}} />
      <Card className="w-full flex flex-col bg-sec-primary rounded-lg p-6 gap-3">
        <div className="w-full flex-col sm:flex-row gap-3 flex justify-between border-b border-sec pb-6">
          <div className="text-2xl font-semibold">
            <span className="text-white">BTC/USDsT</span>
          </div >
          <div className="text-2xl font-semibold">
            <span className="text-yellow-400">{currentPrice}</span>
            <span className="px-2">/</span>
            <span className={clsx("", {
              "text-green-500": priceStatus === "Ascending",
              "text-red-500": priceStatus === "Descending",
            })}>{orderbookPrice}</span>
          </div>
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-3  gap-3 ">
          {orderbook.map((orderbookItem, index) => (
            <OrderbookItem orderbookItem={orderbookItem} key={index} />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import moment from 'moment';

type Signal = {
    pair: string,
    timeframe: string,
    side: string,
    price: number,
    strategy: any,
    time: any
}

function TimeStrategyTable({ signalFront, title }: any) {
    const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setRenderCount(prevCount => prevCount + 1);
        }, 10000);

        return () => clearInterval(interval); // پاک کردن interval در زمان unmount
    }, [renderCount]);

    const timeframe = [
        {
            "timeframe": "1m"
        }, {
            "timeframe": "3m"
        },{
            "timeframe": "5m"
        },  {
            "timeframe": "15m"
        }, {
            "timeframe": "15m"
        }, {
            "timeframe": "30m",
        }, {
            "timeframe": "1h",
        }
    ]

    const timeframes = Array.from(new Set(timeframe.map((item: any) => item.timeframe))) || [];
    const strategies = Array.from(new Set(signalFront && signalFront.map((item: Signal) => item.strategy))) || [];

    return (
        <div>
            <div className='w-full p-4 bg-sec rounded-2xl mb-3'>
                <div className='text-white'>{title}</div>
            </div>
            <div className="shadow-lg  border border-white/15  overflow-x-auto  w-full h-full rounded-2xl">
                <table className='table table-fixed overflow-hidden   h-full ' dir='ltr'>
                    <thead className='bg-sec text-white'>
                        <tr>
                            <th></th>
                            {timeframes && timeframes.map((timeframe, index) => (
                                <th key={timeframe} className={`text-center`}>{timeframe}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='bg-sec '>
                        {strategies && strategies.map((strategy: any, index) => {
                            let strategyName;
                            if (strategy === "Turtle Trade Channels Indicator") {
                                strategyName = "Turtle"
                            } else if (strategy === "SuperBollingerTrend") {
                                strategyName = "Super"

                            } else if (strategy === "UT Bot Alerts") {
                                strategyName = "UT Bot"

                            } else if (strategy === "Pivot Trendlines with Breaks") {
                                strategyName = "Pivot"

                            } else if (strategy === "buy the dips - sell the tops") {
                                strategyName = "Duy dips"

                            } else if (strategy === "Limited Fisher Transform") {
                                strategyName = "Limit"

                            }
                            return (
                                <tr key={strategy} className={` ${index % 2 === 0 ? "bg-primary/60" : ""} `}>
                                    <th className={` text-white w-full !pl-1 text-center lg:text-left xl:text-center text-xs `}>{strategyName}</th>
                                    {timeframes.map((timeframe, index) => {
                                        const cellData = signalFront.find((item: any) => item.timeframe === timeframe && item.strategy === strategy);
                                        const timeNowLocal = moment(); // زمان محلی فعلی
                                        const timeNowUTC = moment.utc(timeNowLocal).format(); // تبدیل به زمان UTC
                                        const cellDataTime = cellData && cellData.time; // فرض کنید cellData.time زمان محلی مورد نظر است
                                        const dateLocal = moment.utc(cellDataTime).format(); // تبدیل زمان محلی مورد نظر به شیء moment

                                        const diffMinutes = moment(timeNowUTC).diff(moment(dateLocal), "minutes") + " " + "m"; // محاسبه اختلاف زمان به دقایق

                                        return (
                                            <td className={`border-l  px-0  border-black text-center 
                                                ${cellData && cellData?.side === "short" || cellData?.side === "exit_long"
                                                    ? "bg-red-500/50 text-white"
                                                    : cellData && cellData?.side === "long" || cellData?.side === "exit_short"
                                                        ? "bg-green-500/50 text-white" : ""}
                                                `} key={timeframe}>
                                                {cellData && cellData?.side === "exit_long" ?
                                                    <div className='flex flex-col  justify-between gap-y-3'>
                                                        <span className='flex gap-x-1  xl:flex-row justify-center   flex-col'>
                                                            <span className='text-xs  self-center'>long</span>
                                                            <span className='text-xs self-center'>(exit)</span>
                                                        </span>
                                                        <span className='text-xs'>{diffMinutes}</span>
                                                    </div>
                                                    : cellData && cellData?.side === "exit_short" ?
                                                        <div className='flex flex-col justify-between gap-y-3'>

                                                            <span className='flex xl:flex-row flex-col justify-center gap-x-1'>
                                                                <span className='text-xs  self-center'>short</span>
                                                                <span className='text-xs self-center'>(exit)</span>
                                                            </span>
                                                            <span className='text-xs'>{diffMinutes}</span>
                                                        </div>
                                                        : cellData ?
                                                            <div className='flex flex-col justify-between gap-y-3'>
                                                                <span className='text-xs '>{cellData.side}</span>
                                                                <span className='text-xs'>{diffMinutes}</span>
                                                            </div>
                                                            : ''}

                                            </td>
                                        );
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TimeStrategyTable;

export const getResponseData = (response: any) => {
  return response.data?.data;
};

export const getResponseSuccess = (response: any) => {
  return response.data ? response.data.message : "عملیات با موفقیت انجام شد";
};

export const getResponseError = (error: any) => {
  console.log("errorerror",error);
  
  return (
    error.response?.data.title ||
    error.response?.data.error ||
    error.response?.data.message ||
    "خطا در برقراری ارتباط  "
  );
};

export const getErrorStatus = (error: any) => {
  // console.log("e======",error);
  // console.log("ttttt======",error.response);

  return "خطای نامشخص";
};

import useGetFormErrorMessage from "../../../Hooks/ReactHookForm/useGetFormErrorMessage";
import getInputPlaceholder from "../../sections/Form/Input/utils/getInputPlaceholder";
import InputForm from "../../sections/Form/Input/InputForm";
import formPatterns from "../../../constants/form/formPatterns";
import { requiredInputErrorMassage } from "../../../constants/form/requiredInputMassage";

// export const stopLossNameField = "stopLoss" as const;
// export const stopLossLabel = "اختلاف قیمت (stopLoss)";
export type StopLossType = number;

type Props = {
    notRequired?: boolean;
    checkMinLength?: boolean;
    checkMaxLength?: boolean;
    nameFieldProps: string;
    labelFieldProps: string;
    defaultValue?: string;
    disabled?: boolean;
};

function NumberSettingsField({
    notRequired,
    checkMinLength = true,
    disabled,
    defaultValue,
    labelFieldProps,
    nameFieldProps,
    checkMaxLength = true,
}: Props) {
    const getErrorMessage = useGetFormErrorMessage();
    const errorMessage = getErrorMessage(nameFieldProps);
    const placeholder = getInputPlaceholder(labelFieldProps);

    return (
        <InputForm
            defaultValue={defaultValue}
            fieldName={nameFieldProps}
            inputProps={{
                label: labelFieldProps,
                errorMessage,
                // placeholder,
                inputMode: "numeric",
                ...(disabled && { disabled }),
            }}
            rules={{
                ...(checkMinLength && { minLength: formPatterns.minLengthChar(1) }),
                ...(notRequired ? {} : { required: requiredInputErrorMassage }),
            }}
            {...(checkMaxLength && { maxLength: 10 })}
            justNumber
        />
    );
}

export default NumberSettingsField;

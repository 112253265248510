import React from 'react'
import useBingxSettings from './useBingxSettings'
import Card from "../sections/Card";
import ReactHookFormWrapper from '../sections/Form/ReactHookFormWrapper/ReactHookFormWrapper';
import NumberSettingsField from '../Settings/Fields/NumberSettingsField';
import {
    PriceDifferenceOrderField, PriceDifferenceNumber,
    PercentageNo, CanselTime, CloseLimit, LeverageField, StopLossField, CloseMarket, OpenOrderWithDemo, TreadDone
} from "./LabelAndNameFields"
import Button from '../sections/Button/Button';
import ToggleButtonField from '../Settings/Fields/ToggleButtonField';
const BingxSettings = () => {
    const { formMethods,
        onSubmit, addRow,
        removeRow, rows, handleInputChange } = useBingxSettings()
    return (
        <Card title='تنظیمات صرافی'>
            <ReactHookFormWrapper
                formMethods={formMethods}
                onSubmit={onSubmit}
                hiddenButton
                className={`flex flex-col justify-start py-4  rounded-2xl w-full`}
            >
                <div className="flex flex-col">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3">
                        <NumberSettingsField nameFieldProps={StopLossField.name} labelFieldProps={StopLossField.label} />
                        <NumberSettingsField nameFieldProps={CanselTime.name} labelFieldProps={CanselTime.label} />
                        <NumberSettingsField nameFieldProps={CloseLimit.name} labelFieldProps={CloseLimit.label} />
                        <NumberSettingsField nameFieldProps={LeverageField.name} labelFieldProps={LeverageField.label} />
                        <NumberSettingsField nameFieldProps={PriceDifferenceOrderField.name} labelFieldProps={PriceDifferenceOrderField.label} />
                    </div>

                    <div className="col-span-2 border border-gary-400   p-4 rounded-lg gap-x-3">
                        <div className="text-xl w-full flex justify-start">
                            <span className="text-white">(take profit) های مرحله ای</span>
                        </div >
                        <div className=" flex flex-col  gap-x-4 pt-4">
                            {rows.map((row, index) => (
                                <div key={row.index} className='grid grid-cols-1 sm:grid-cols-2 gap-x-3'>
                                    {/* <input
                                        type="text"
                                        value={row.data1}
                                        name={`data1_${row.id}`} // Unique name for data1
                                        onChange={(e) => handleInputChange(e, row.id, 'data1')}
                                    />
                                    <input
                                        type="text"
                                        value={row.data2}
                                        name={`data2_${row.id}`} // Unique name for data2
                                        onChange={(e) => handleInputChange(e, row.id, 'data2')}
                                    /> */}
                                    <NumberSettingsField defaultValue={'0'} nameFieldProps={`${PriceDifferenceNumber.name}_${index + 1}`} labelFieldProps={`${PriceDifferenceNumber.label} ${index + 1}`} />
                                    <NumberSettingsField defaultValue={'0'} nameFieldProps={`${PercentageNo.name}_${index + 1}`} labelFieldProps={`${PercentageNo.label} ${index + 1}`} />
                                </div>
                            ))}
                            <div className='flex w-full gap-x-6 justify-start pt-4'>
                                <Button color="primary"
                                    // size="md"
                                    className={{ extra: "md:px-4 md:py-2 p-2 w-24 sm:text-base text-xs    sm:w-44  rounded-lg" }}
                                    onClick={addRow}
                                >افزودن سطر</Button>
                                <Button color="primary"
                                    // size="md"
                                    className={{ extra: "md:px-4 md:py-2 p-2 w-28 sm:text-base text-xs sm:w-44  rounded-lg" }}
                                    onClick={removeRow}
                                >حذف آخرین سطر</Button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3  sm:flex-rw flex-col flex gap-x-6 gap-y-2 pt-3 w-full px-3">
                        <ToggleButtonField nameFieldProps={TreadDone.name} labelFieldProps={TreadDone.label} />
                        <ToggleButtonField nameFieldProps={OpenOrderWithDemo.name} labelFieldProps={OpenOrderWithDemo.label} />
                        <ToggleButtonField nameFieldProps={CloseMarket.name} labelFieldProps={CloseMarket.label} />
                    </div>
                </div>
                <div className="flex gap-2 pt-4  justify-start">
                    <>
                        <Button
                            color="primary"
                            size="md"
                            type="submit"
                            className={{ extra: "px-4 py-2 rounded-lg" }}
                        >
                            اعمال تنظیمات
                        </Button>
                    </>
                </div>
            </ReactHookFormWrapper>
        </Card>

    )
}

export default BingxSettings
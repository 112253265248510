import {InputProps} from "./types/InputProps";


type Props = {
  endAdornmentAspect?: InputProps['endAdornmentAspect'],
  endAdornmentClass?: InputProps['endAdornmentClass'],
  endAdornmentPadding?: InputProps['endAdornmentPadding'],
  endAdornment: InputProps['endAdornment'],
}

function InputEndAdornment(
  {
    endAdornmentAspect, endAdornmentClass, endAdornment, endAdornmentPadding
  }: Props
) {
  return (
    <div
      className={`flex items-center absolute left-0 top-6 inset-y-0 my-auto h-3/4 ${endAdornmentAspect || 'aspect-square'} ${endAdornmentPadding || 'py-2.5 px-4'} ${endAdornmentClass || ''}`}>
      {endAdornment}
    </div>
  );
}

export default InputEndAdornment
import { Types } from "../../../type/types";

type Props = {
  children: Types["children"];
  className?: string;
  title?: string
};

function Card({ children, className, title }: Props) {
  return (
    <div
      className={`bg-sec-primary  rounded-lg w-full  h-fit      p-5 ${className}`}
    >
      {title && <div className="w-full flex-col sm:flex-row gap-3 flex justify-start border-b border-sec pb-6">
        <div className="text-xl">
          <span className="text-white">{title}</span>
        </div >
      </div>}
      {children}
    </div>
  );
}

export default Card;

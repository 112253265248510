import {EnRegexes} from "../EnRegexes";

const formPatterns = {
  password: {
    value: EnRegexes.password,
    message: 'حداقل ۸ رقم و شامل حروف بزرگ و کوچک انگلیسی، اعداد و کاراکترهای خاص.'
  },
  withouSpace: {
    value: EnRegexes.withoutSpace,
    message: 'فاصله مجاز نیست.'
  },
  numeric: {
    value: EnRegexes.numericRegex,
    message: 'فقط عدد وارد کنید'
  },
  minLengthChar: (value:number) => ({
    value,
    message: `باید بیشتر از ${value} کاراکتر باشد`,
  }),
  maxLengthChar: (value:number) => ({
    value,
    message: `باید کمتر از ${value} کاراکتر باشد`,
  }),
  specificLengthChar: (value:number) => ({
    value,
    message: `باید ${value} کاراکتر باشد`,
  }),
}

export default formPatterns;
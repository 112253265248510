import { useEffect, useRef, useState } from "react";
import { socketClient } from "../../Socket";

interface RealSnapshotType {
  lastUpdateId: number;
  asks: string[][];
  bids: string[][];
  price: number;
}

const useRealSnapShot = () => {
  const [realSnapshotData, setRealSnapshotData] = useState<RealSnapshotType>({
    lastUpdateId: 0,
    asks: [],
    bids: [],
    price: 0,
  });

  const offsetRef = useRef(500);
  const [offsetInput, setOffsetInput] = useState(500);

  const offsetHandler = () => {
    offsetRef.current = offsetInput;
  };

  useEffect(() => {
    socketClient.emit("join-page", "realSnapShot");

    socketClient.on("realShot", (data: RealSnapshotType) => {
      if (offsetRef.current > 0) {
        const filteredAsks = data.asks.filter(
          ([price, depth]: any) =>
            parseFloat(price) <= data.price + offsetRef.current
        );
        const filteredBids = data.bids.filter(
          ([price, depth]: any) =>
            parseFloat(price) >= data.price  - offsetRef.current
        );

        setRealSnapshotData({
          lastUpdateId: data.lastUpdateId,
          asks: filteredAsks,
          bids: filteredBids,
          price: data.price,
        });
      } else {
        setRealSnapshotData({ ...data });
      }
    });

    return () => {
      socketClient.off("realShot");
    };
  }, []);

  return {
    realSnapshotData,
    offsetHandler,
    setOffsetInput,
    offsetInput,
  };
};

export default useRealSnapShot;

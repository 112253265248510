export function removeItemFromObjectWhenIsNullOrEmptyString(object:Object) {
    Object?.entries(object).forEach(item => {
      //@ts-ignore
      if (isNullOrEmptyString(item[1])) delete object[item[0]]
    })
  
    return object
  }
  
  function isNullOrEmptyString(value: any) {
    return value == null || value === ''
  }
  
  export default isNullOrEmptyString
import joinObjectValues from "../../../../utils/joinObjectValues";
import { InputProps } from "./types/InputProps";
import useInputStyles from "./hooks/useInputStyles";
import InputLabel from "./InputLabel";
import InputErrorMessage from "./InputErrorMessage";
import InputEndAdornment from "./InputEndAdornment";

function Input({
  name,
  label,
  placeholder,
  value,
  defaultValue,
  autoCompleteOff,
  type,
  inputMode,
  disabled,
  wrapperClassName,
  inputClassName,
  labelClassName,
  errorMessage,
  onChange,
  onBlur,
  onKeyDown,
  inputRef,
  endAdornment,
  startAdornment,
  hiddenErrorMessageElement,
  onClick,
  inputWrapperOnClick,
  justSelectOnClick,
  fileInput,
  endAdornmentAspect,
  labelEndAdornment,
  labelEndAdornmentOnClick,
  onKeyPress,
  rows,
  required,
  readOnly,
  endAdornmentClass,
  endAdornmentPadding,
}: InputProps) {
  const { wrapperStyles, inputStyles, labelStyles } = useInputStyles({
    wrapperClassName,
    inputClassName,
    labelClassName,
    errorMessage,
    startAdornment,
  });

  const inputProps = {
    ...(name && { id: name }),
    ...(inputRef && { ref: inputRef }),
    ...(value != null ? { value } : {}),
    ...(autoCompleteOff && { autoComplete: "password" }),
    ...(defaultValue && { defaultValue }),
    ...(onChange && { onChange }),
    ...(onClick && { onClick }),
    ...(rows && { rows }),
    ...((justSelectOnClick || readOnly) && { readOnly: true }),
    placeholder: placeholder || "",
    inputMode: inputMode || "text",
    type: type || "text",
    disabled: Boolean(disabled),
    className: joinObjectValues(inputStyles),
    onBlur,
    onKeyDown,
    onKeyPress,
  };
  console.log("errorMessage", errorMessage);

  return (
    <div className={joinObjectValues(wrapperStyles)}>
      {/* {label && (
        <div className={joinObjectValues(labelStyles)}>
          <InputLabel {...{ label, name, required }} />

          {labelEndAdornment && (
            <button
              onClick={labelEndAdornmentOnClick}
              type="button"
              className="text-xs text-primary font-medium"
            >
              {labelEndAdornment}
            </button>
          )}
        </div>
      )} */}

      <div
        {...(inputWrapperOnClick && { onClick: inputWrapperOnClick })}
        className="relative  rounded-xl "
      >
        {startAdornment && (
          <div
            className={`flex items-center justify-center absolute right-0 inset-y-0 my-auto h-full p-2 w-12`}
          >
            {startAdornment}
          </div>
        )}

        {rows ? (
          <textarea {...inputProps} />
        ) : (
          // ) : <input {...inputProps} />
          <div className=" flex flex-col items-start">
            <label
              dir="rtl"
              {...(name && { htmlFor: name })}
              className={` `}
            >
              <p className="lg:text-lg sm:text-sm  text-xs text-white pb-2 font-IranBold px-2">{label}</p>
            </label>
            <input
              {...inputProps}
              className={` ${
                errorMessage ? "border-2 border-red-500" : ""
              }  px-3 py-2 w-full  rounded-lg text-white bg-sec`}
              // ref={inputRef}
              // className="border-none outline-none"
              // id={id}
              // name={id}
              // type={inputType ? inputType : "text"}
              // placeholder={placeholder}
              // onChange={setValue}
              // value={value}
            />
          </div>
        )}

        {fileInput || null}

        {endAdornment && (
          <InputEndAdornment
            endAdornment={endAdornment}
            endAdornmentAspect={endAdornmentAspect}
            endAdornmentClass={endAdornmentClass}
            endAdornmentPadding={endAdornmentPadding}
          />
        )}

        {justSelectOnClick && (
          <div
            {...(typeof justSelectOnClick === "function" && {
              onClick: justSelectOnClick,
            })}
            tabIndex={0}
            className={`w-full h-full absolute inset-0 cursor-pointer border-1 rounded-lg duration-200 focus:border-primary/50
         ${
           errorMessage
             ? `border-red-500`
             : `border-gray-300 hover:border-gray-400 `
         }    `}
          />
        )}
      </div>

      {!hiddenErrorMessageElement && (
        <InputErrorMessage errorMessage={errorMessage} />
      )}
    </div>
  );
}

export default Input;

import useGetFormErrorMessage from "../../../Hooks/ReactHookForm/useGetFormErrorMessage";
import getInputPlaceholder from "../../sections/Form/Input/utils/getInputPlaceholder";
import InputForm from "../../sections/Form/Input/InputForm";
import formPatterns from "../../../constants/form/formPatterns";
import { requiredInputErrorMassage } from "../../../constants/form/requiredInputMassage";

export const usernameFildeName = 'username' as const
export const usernameLabel = 'نام کاربری'
export type UsernameType = string

type Props = {
  notRequired?: boolean
  checkMinLength?: boolean
  checkMaxLength?: boolean
}

function UserNameField({notRequired, checkMinLength = true,checkMaxLength = true}: Props) {

  const getErrorMessage = useGetFormErrorMessage()
  const errorMessage = getErrorMessage(usernameFildeName)
  const placeholder = getInputPlaceholder(usernameLabel)

  return (
    <InputForm
      fieldName={ usernameFildeName}
      inputProps={{
        label: usernameLabel,
        errorMessage,
        placeholder,
      }}
      rules={{
        ...checkMinLength && {minLength: formPatterns.minLengthChar(3)},
        ...notRequired ? {} : {required: requiredInputErrorMassage},
      }}
      {...checkMaxLength && {maxLength:50} }
    />
  );
}

export default UserNameField;
import axios from "axios";
import { useEffect, useState } from "react";
import { Api } from "../../Api";
import { useNavigate } from "react-router-dom";

export interface SnapShothistoryType {
  lastUpdateId: number;
  createdAt: string;
  _id:string
}

const useSnapshotHistory = () => {
  const navigate = useNavigate();

  const [snapShothistoryData, setSnapshotHistoryData] = useState<
    SnapShothistoryType[]
  >([]);
  const [reload,setReload]=useState(false)
  const getData = async () => {
    const response = await axios({ url: Api.getSnapshotHistories });
    setSnapshotHistoryData(response.data.data);
  };

  const onDeleteSnapshotHistory = async (id: string) => {
    try {
      await axios({method:"Delete", url: `${Api.deleteSnapshotHistory}/${id}` });
      setReload(!reload)
      window.alert("با موفقیت حذف شد");
    } catch (error) {
      window.alert("مشکلی پیش آمده");
    }
  };
  const onShowSnapshotDetail = async (id: string) => {
    navigate(`/SnapshotDetails/${id}`);
    
  };

  useEffect(() => {
    getData();
  }, [reload]);

  return { snapShothistoryData,onDeleteSnapshotHistory,onShowSnapshotDetail };
};
export default useSnapshotHistory;

import {useController, useFormContext} from "react-hook-form";
import Toggle, {ToggleProps} from "./Toggle";
import {FormInputProps} from "../../../../type/FormInputProps";
import { log } from "console";


type ToggleFormProps = {
  inputProps?: ToggleProps,
  domainActiveForCreate?:boolean,
  setDomainActiveForCreate?:any
} & Pick<FormInputProps, 'rules' | 'fieldName'>

function ToggleForm({inputProps,rules, fieldName,domainActiveForCreate,setDomainActiveForCreate}: ToggleFormProps) {

  const {control} = useFormContext()

  const {
    field: {onChange, onBlur, name, value, ref},
  } = useController({
    name: fieldName,
    control,
    rules,
    defaultValue: "",
  });
  return (
    <Toggle
      name={name}
      checked={value}
      inputRef={ref}
      onBlur={onBlur}
      onChange={onChange}
      {...inputProps}
    />
  );
}

export default ToggleForm
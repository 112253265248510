import React from 'react'
import useDemo from './useDemo';
import OrderRecentTable from './components/OrderRecentTable';
import Card from '../sections/Card'
import Button from '../sections/Button/Button'
import RecentOrderTable from './components/RecentOrderTable';
function Demo() {
  const { statusRecord, data, deleteFuturesHistoryHandler, signalFront, ethsignalFront } = useDemo();

  return (
    <Card title='دمو'>
      <OrderRecentTable statusRecord={statusRecord} signalFront={signalFront} ethsignalFront={ethsignalFront} />

      <RecentOrderTable data={data} title={"Orders History"} children={
        <Button size='sm'
          onClick={deleteFuturesHistoryHandler}
          disable={data?.length < 1 || !data?.length ? true : false}
          className={{
            extra: "px-6 py-3"
          }}>
          پاک کردن تاریخچه
        </Button>
      } />


    </Card>
  )
}

export default Demo 
